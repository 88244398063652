import React from 'react';
import Navbar from './../Navbar';
import Footer from './../Footer';

export const MobileLayout = (props) => {
    const {
        children
    } = props;
    const ENV = 'mobile';
    return (
        <div className='hlLayout-mobile hlLayout'>
            <Navbar env={ENV} />
            {children}
            <Footer />
        </div>
    );
};