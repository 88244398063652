import React from 'react';
import ViewTitle from './ViewTitle';
import ViewLine from './ViewLine';
import { HlFlexBox, HlFlexItem } from './flex';

const About = (props) => {
  const { env } = props;
  const workHistorys = [
    {
      year: 2024,
      partner: '상성카드',
      project: ['오픈소스 관리체계 구축 기능 개선']
    },
    {
      year: 2023,
      partner: '상성카드',
      project: ['오픈소스 관리체계 구축']
    },
    {
      year: 2023,
      partner: '상성증권',
      project: ['오픈소스 관리체계 구축']
    },
    {
      year: 2023,
      partner: 'KTDS',
      project: ['보안 관제 포탈 고도화']
    },
    {
      year: 2022,
      partner: '현대백화점',
      project: ['VIP 제도개선 TCP 이원화']
    },
    {
      year: 2022,
      partner: '에이비클',
      project: ['SiteChecker']
    },
    {
      year: 2022,
      partner: 'KTDS',
      project: ['보안 관제 포탈']
    },
    {
      year: 2021,
      partner: 'NIMBUS NETWORKS',
      project: ['참여방송을 기반으로 한 지능형 교육 플랫폼']
    },
    {
      year: 2020,
      partner: 'SOOM VI',
      project: ['드론 비행 촬영을 통한 스트리밍 시스템']
    },
    {
      year: 2019,
      partner: '연세대학교 의료원',
      project: ['갤럭시 워치를 이용한 헬스케어 시스템']
    },
    {
      year: 2019,
      partner: 'SOOM VI',
      project: ['드론을 이용한 구조 및 재난 방지 시스템']
    }, 
    {
      year: 2018,
      partner: 'CISCO',
      project: ['보안 솔루션 운영']
    },
    {
      year: 2017,
      partner: '해시랩',
      project: ['보안 업무 처리 솔루션 개발', '보안 탐지 시나리오 컨설팅']
    },
  ];

  return (
    <div className='page page-about' id='about'>
        <div className='container container-about'>
          <ViewTitle view='about'/>
          <HlFlexBox
            className='viewContents viewContents-about'
            direction='column'
          >
            <HlFlexItem>
              <HlFlexBox
                justifyContent='spaceBetween'
                direction={env === 'desktop' ? 'row' : 'column'}
                gutterSize='s'
              >
                <HlFlexItem
                  glow={1}
                >
                  <div>
                    <div className='image-wrap'>
                      <img src='/images/about_1.png' alt='about_1' />
                    </div>
                    <div className='text-box'>
                      <h4 className='head-title'># HASH LAB</h4>
                      <p>
                        <span className='sub-title'>" 아무것도 확실치 않을때, 모든것이 가능해 집니다 "</span>
                        <br />
                        <br />
                        <span>"확실한 것이 없다고 할 수 없는 것은 아니다"</span>
                        <br />
                        <span>‌해시랩은 10년 이상 함께한 임직원들과 힘을 합처 많은 어려움이 있더라도 이를 해결해 가며 개발해 왔습니다.</span>
                      </p>
                    </div>
                  </div>
                </HlFlexItem>
                <HlFlexItem
                  glow={1}
                >
                  <div>
                    <div className='image-wrap'>
                      <img src='/images/about_2.png' alt='about_2' />
                    </div>
                    <div className='text-box'>
                      <h4 className='head-title'># WE ARE</h4>
                      <p>
                        <span className='sub-title'>사람 냄새가 나는 IT 관련 전문가 집단 입니다.</span>
                        <br />
                        <br />
                        <span>특히 IT 회사에서는 사람이 제일 중요하고 구성원이 중심이 되어야 하므로  규모만 큰 회사보다는 규모는 작지만 사람 중심의 큰 회사를 지향합니다.</span>
                      </p>
                    </div>
                  </div>
                </HlFlexItem>
                <HlFlexItem
                  glow={1}
                >
                  <div>
                    <div className='image-wrap'>
                      <img src='/images/about_3.png' alt='about_3' />
                    </div>
                    <div className='text-box'>
                      <h4 className='head-title'># AI</h4>
                      <p>
                        <span className='sub-title'>인공지능 관련 기술에 집중하고 있습니다.</span>
                        <br />
                        <br />
                        <span>해시랩은 앞으로 인공지능 기술이 모든 산업에서 큰 비중을 차지할 것이라고 생각하고 회사의 임직원 전원이 인공지능 관련 기술 개발에 집중하고 있습니다.</span>
                      </p>
                    </div>
                  </div>
                </HlFlexItem>
              </HlFlexBox>
            </HlFlexItem>
            <HlFlexItem>
              <HlFlexBox
                className='contents-about contents-about-history'
                direction='column'
                alignItems='center'
              >
                <HlFlexItem>
                  <div>
                    <i></i>
                    <h6>H &nbsp; I &nbsp; S &nbsp; T &nbsp; O &nbsp; R &nbsp; Y</h6>
                    <i></i>
                  </div>
                  <h3 className='head-title'>
                    <span>H &nbsp;&nbsp; A &nbsp;&nbsp; S &nbsp;&nbsp; H &nbsp;&nbsp;</span>
                    <span className='sharp'># &nbsp;&nbsp;</span>
                    <span>L &nbsp;&nbsp; A &nbsp;&nbsp; B</span>
                  </h3>
                  <p>
                    <span>해시랩은 IT 관련 전문 기술을 가진 회사입니다.</span>
                    <br />
                    <span>-------- 머신러닝 / 빅데이터 / AI 관련 --------</span>
                  </p>
                  <span></span>
                </HlFlexItem>
                <HlFlexItem>
                  <HlFlexBox
                    direction='column'
                    alignItems='center'
                    gutterSize='m'
                  >
                    {workHistorys.map((workHistory, index) => 
                      <HlFlexItem
                        key={index}
                        className='workHistory'
                      >
                        <p className='workHistory-year'>{workHistory.year}</p>
                        <span>{workHistory.partner}</span>
                        <br />
                        <span>-{workHistory.project}-</span>
                      </HlFlexItem>  
                    )}
                  </HlFlexBox>
                </HlFlexItem>
              </HlFlexBox>
            </HlFlexItem>
          </HlFlexBox>
          <ViewLine />
        </div>
      </div>
  );
};

export default About;