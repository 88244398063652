import React, { useState } from 'react';
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai';
import { Link } from 'react-scroll';

const Navbar = ({ env }) => {
  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    setClicked(!clicked);
  }

  return (
    <nav className={`navbar navbar-${env}`}>
      <div className='navbar-container'>
        <div className='navbar-logo'>
          <a href='#'><img src='/images/hashlab_logo.png' alt='hashlab_navbar_logo'/></a>
        </div>
          <ul className={clicked ? 'navbar-menu active' : 'navbar-menu'}>
            <li>
              <Link to='home' spy={true} smooth={true}>
                <a href='#HOME' className='navbar-links' onClick={handleClick}>HOME</a>
              </Link>
            </li>
            <li>
              <Link to='about' spy={true} smooth={true}>
                <a href='#ABOUT' className='navbar-links' onClick={handleClick}>ABOUT</a>
              </Link>
            </li>
            <li>
              <Link to='work' spy={true} smooth={true}>
                <a href='#WORK' className='navbar-links' onClick={handleClick}>WORK</a>
              </Link>
            </li>
            <li>
              <Link to='partner' spy={true} smooth={true}>
                <a href='#PARTNER' className='navbar-links' onClick={handleClick}>PARTNER</a>
              </Link>
            </li>
            <li>
              <Link to='contact' spy={true} smooth={true}>
                <a href='#CONTACT' className='navbar-links' onClick={handleClick}>CONTACT</a>
              </Link>
            </li>
          </ul>
        <div className='navbar-menu-icon' onClick={handleClick}>
          {!clicked ? 
            <AiOutlineMenu 
              className='menu-icon'
              size='30' 
            />
            :
            <AiOutlineClose 
              className='menu-icon'
              size='30'
            />
          }
        </div>
      </div>
    </nav>
  );
};

export default Navbar;