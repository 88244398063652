import React from 'react';
import { HlFlexBox, HlFlexItem } from './flex';
import ViewLine from './ViewLine';
import ViewTitle from './ViewTitle';
import { PROJECTS } from './../assets/projects';

const Work = (props) => {
  const { env } = props;

  const clickWork = e => {
    e.preventDefault();
    
  }

  return (
    <div className='page page-work' id='work'>
      <div className='container container-work'>
        <ViewTitle view='work' />
        <HlFlexBox
          direction='column'
          className='viewContents viewContents-work'
        >
          {PROJECTS && PROJECTS.map((project, key) => 
            (key % 2 === 0) ?
              <HlFlexItem
                key={key}
              >
                <HlFlexBox
                  direction={env === 'desktop' ? 'row' : 'column'}
                  className='contents-work'
                >
                  <HlFlexItem
                    className='image-wrap'
                    style={{background: `url(${project.image})`}}
                  >
                  </HlFlexItem>
                  <HlFlexItem
                    className='text-box even'
                  >
                    <div>
                      <h3><span>{project.title}</span></h3>
                      <h4>
                        <span>{project.subTitle}</span>
                        <span> [ {project.classification} ]</span>
                      </h4>
                      <p>
                        <span>{project.partner}</span>
                        <br />
                        <br />
                        {project.descriptions && project.descriptions.map((description, index) => 
                          <React.Fragment key={index}>
                            <span>{description}</span>
                            <br />
                          </React.Fragment>
                        )}
                      </p>
                      <br />
                      {project.tags && project.tags.map((tag, index) => 
                        <span key={index}>#{tag}&nbsp;</span>
                      )}
                      <br />
                      <br />
                      {project.link !== '' && <a href={project.link} target='_blank' rel='noopener noreferrer'>데모 보기</a>}
                    </div>
                  </HlFlexItem>
                </HlFlexBox>
              </HlFlexItem>
              :
              <HlFlexItem
                key={key}
              >
                <HlFlexBox
                  direction={env === 'desktop' ? 'row' : 'columnReverse'}
                  className='contents-work'
                >
                  <HlFlexItem
                    className='text-box odd'
                  >
                    <div>
                      <h3><span>{project.title}</span></h3>
                      <h4>
                        <span>{project.subTitle}</span>
                        <span> [ {project.classification} ]</span>
                      </h4>
                      <p>
                        <span>{project.partner}</span>
                        <br />
                        <br />
                        {project.descriptions && project.descriptions.map((description, index) => 
                          <React.Fragment key={index}>
                            <span>{description}</span>
                            <br />
                          </React.Fragment>
                        )}
                      </p>
                      <br />
                      {project.tags && project.tags.map((tag, index) => 
                        <span key={index}>#{tag}&nbsp;</span>
                      )}
                      <br />
                      <br />
                      {project.link !== '' && <a href={project.link} target='_blank' rel='noopener noreferrer'>데모 보기</a>}
                    </div>
                  </HlFlexItem>
                  <HlFlexItem
                    className='image-wrap'
                    style={{ background: `url(${project.image})` }}
                  >
                  </HlFlexItem>
                </HlFlexBox>
              </HlFlexItem>
          )}
        </HlFlexBox>
        <ViewLine />
      </div>
    </div>
  );
};

export default Work;
