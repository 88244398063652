import React from 'react';
import ViewContents from './ViewContents';

const Home = (props) => {
  const { env } = props;
  
  return (
    <div className='page page-home' id='home'>
      <div className='text-box'>
        <h2><strong>HASH # LAB</strong></h2>
        <h3><strong>When nothing is sure, everything is possible</strong></h3>
        <span>"아무것도 확시리 않을 때, 모든 것이 가능해진다."</span>
        <br />
        <br />
        <p>
          <span>해시랩은 IT 관련 다양한 전문가들이 모여서 만든 회사이며</span>
          <br />
          <span>10여년간 호흡을 맞춘 구성원들과 함께 하고 있습니다.</span>
        </p>
      </div>
    </div>
  );
};

export default Home;