import React from 'react';
import ViewTitle from './ViewTitle';
import ViewLine from './ViewLine';

const Partner = () => {
  const PARTNERS = [
    {
      id: 1,
      name: 'CISCO',
      imageUrl: '/images/partners/cisco.png'
    },
    {
      id: 2,
      name: 'YONSEI UNIVERSITY HEALTH SYSTEM',
      imageUrl: '/images/partners/yonsei.png'
    },
    {
      id: 3,
      name: 'SOOM VI',
      imageUrl: '/images/partners/soomvi.png'
    },
    {
      id: 4,
      name: 'NIMBUS NETWORKS',
      imageUrl: '/images/partners/nimbus.png'
    },
    {
      id: 5,
      name: 'KTDS',
      imageUrl: '/images/partners/ktds.png'
    },
    {
      id: 6,
      name: '에이비클',
      imageUrl: '/images/partners/abcle.png'
    },
    {
      id: 7,
      name: '현대백화점',
      imageUrl: '/images/partners/abcle.png'
    },
    {
      id: 8,
      name: '삼성카드',
      imageUrl: '/images/partners/samsung_card.png'
    },
  ];

  return (
    <div className='page page-partner' id='partner'>
      <div className='container container-partner'>
        <ViewTitle view='partner' />
        <div className='viewContents viewContents-partner'>
          <div className='partner-grid'>
            {PARTNERS.length > 0 && PARTNERS.map((partner, index) => 
              <div key={index}>
                <div className='img-wrap'>
                  <img src={partner.imageUrl} alt={partner.name} />
                </div>
                <div className='text-box'>
                  <p>{partner.name}</p>
                </div>
              </div>
            )}
          </div>
        </div>
        <ViewLine />
      </div>
    </div>
  );
};

export default Partner;