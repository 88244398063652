import React from 'react';

const ViewLine = () => {
    return (
        <div className='view-line'>
            <div>
                <hr />
                <div className='diamond-box'>
                    <span className='diamond-line'></span>
                    <span className='diamond-line'></span>
                    <span className='diamond-line'></span>
                    <span className='diamond-line'></span>
                </div>
                <hr />
            </div>
        </div>
    );
};

export default ViewLine;