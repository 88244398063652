import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { DesktopLayout, TabletLayout, MobileLayout } from './components/layouts';
import Home from './components/Home';
import About from './components/About';
import Work from './components/Work';
import Partner from './components/Partner';
import Contact from './components/Contact';
import FooterLine from './components/FooterLine';
import './css/common.css';
import Message from './components/Message';

const LayoutContents = (props) => {
  return (
    <React.Fragment>
      <Home env={props.env} />
      <About env={props.env} />
      <Work env={props.env} />
      <Partner env={props.env} />
      {/* <Contact env={props.env} /> */}
      {/* <Message env={props.env} /> */}
      <FooterLine />
    </React.Fragment>
  );
};

function App() {
  const isDesktop = useMediaQuery({ minWidth: 769 })
  // const isTablet = useMediaQuery({ minWidth: 769, maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return (
    <React.Fragment>
      {isDesktop && 
        <DesktopLayout>
          <LayoutContents env='desktop' />
        </DesktopLayout>
      }
      {/* {isTablet && 
        <TabletLayout>
              
        </TabletLayout>
      } */}
      {isMobile && 
        <MobileLayout>
          <LayoutContents env='mobile'/>
        </MobileLayout>
      }
    </React.Fragment>
  );
}

export default App;
