export const PROJECTS = [
    {
      id:11,
      title: '삼성카드 오픈소스 관리체계 구축 기능 개선',
      subTitle: '',
      classification: '취약점 분석',
      link: '',
      partner: '삼성카드',
      image: '/images/work_enterprise_management_system.png',
      description: [
        '',
      ],
      tags: [
        'Inspection', 'Analysis', 'Vulnerability', 'Dashborad'
      ]
    },
    {
      id:10,
      title: '삼성카드 오픈소스 관리체계 구축',
      subTitle: '',
      classification: '취약점 분석',
      link: '',
      partner: '삼성카드',
      image: '/images/work_health_care.png',
      description: [
        '',
      ],
      tags: [
        'Inspection', 'Analysis', 'Vulnerability', 'Dashborad'
      ]
    },
    {
      id:9,
      title: '삼성증권 오픈소스 관리체계 구축',
      subTitle: '',
      classification: '취약점 분석',
      link: '',
      partner: '삼성증권',
      image: '/images/work_security_management.png',
      description: [
        '',
      ],
      tags: [
        'Inspection', 'Analysis', 'Vulnerability', 'Dashborad'
      ]
    },
    {
      id:8,
      title: 'Enterprise Security Management 고도화',
      subTitle: 'Security & Dashboard',
      classification: '보안관제',
      link: '',
      partner: 'KTDS',
      image: '/images/work_ai.png',
      description: [
        '보안 관제 분석 대시보드',
      	'침해 위협 탐지',
      ],
      tags: [
        'Security', 'Dashboard'
      ]
    },
    {
      id:7,
      title: '현대백화점VIP 제도개선 TCP 이원화',
      subTitle: '',
      classification: '',
      link: '',
      partner: '현대백화점',
      image: '/images/work_enterprise_management_system.png',
      description: [
        'VIP 제도 개선',
      ],
      tags: []
    },
    {
      id:6,
      title: 'SiteChecker',
      subTitle: 'Web Crawling',
      classification: '웹 크롤링',
      link: '',
      partner: '에이비클',
      image: '/images/work_health_care.png',
      description: [

      ],
      tags: [
        'Crawling'
      ]
    },
    {
      id:5, 
      title: 'Enterprise Security Management',
      subTitle: 'Security & Dashboard',
      classification: '보안관제',
      link: '',
      partner: 'KTDS',
      image: '/images/work_security_management.png',
      descriptions: [
        '보안 관제 분석 대시보드',
        '침해 위협 탐지',
      ],
      tags: [
        'Security', 'Dashboard'
      ],
    },
    {
      id:4, 
      title: 'Intelligent Education Platform',
      subTitle: 'Object & Concentration Detection',
      classification: '객체인식',
      link: 'http://158.247.207.152:8001',
      partner: '팀그릿 & 님버스 네트웍스',
      image: '/images/work_ai.png',
      descriptions: [
        '방송 참여자 영상 분석(집중도) 및 객체 검출',
        '영상 분석 및 객체 검출 알고리즘의 클라이언트 적용'
      ],
      tags: [
        'Deep Learning', 'Object Detection'
      ],
    },
    {
      id:3,
      title: 'Drone Management System',
      subTitle: 'Drone',
      classification: '통합관제',
      link: '',
      partner: '숨비',
      image: '/images/work_enterprise_management_system.png',
      descriptions: [
        '응급상황 / 재난 관련 통합 관제 시스템 구축',
        '드론을 이용하여 신속하게 임무 수행',
        '드론의 위치 및 영상 정보를 활용한 신속한 정보 공유',
        '드론의 빅데이터를 분석한 사고지역 미연에 순찰',
        'AI를 이용한 드론의 운영 및 유지 관리'
      ],
      tags: [
        'Drone', 'Dashbaord'
      ],
    },
    {
      id: 2,
      title: 'Health Care',
      subTitle: 'Galaxy Watch',
      classification: '헬스케어',
      link: '',
      partner: '연세대학교 의료원',
      image: '/images/work_health_care.png',
      descriptions: [
        '삼성의 갤럭시 워치를 이용한 환자의 정보를 수집 및 전송',
        '환자의 헬스 데이터를 실시간으로 감지하여 응급상황을 미연에 방지',
        'AI를 이용하여 환자들의 빅데이터를 분석하여 앞으로의 예방진료 및 치료에 활용',
        '종합 빅데이터 분석으로 AI기반 의료 기술 발전에 기여'
      ],
      tages: [
        'Health Care'
      ],
    },
    {
      id: 1,
      title: 'Enterprise Security Management',
      subTitle: 'Security',
      classification: '보안관제',
      link: '',
      partner: '시스코',
      image: '/images/work_security_management.png',
      descriptions: [
        '보안 로그 분석 시스템을 이용한 보안 컨설팅',
        'AI 기반의 탐지 시나리오 변경 관리',
        'AI 기반의 사용자 행동 패턴 분석',
        'AI 기반의 보안 업무 자동화 솔루션',
        '보안 네트워크 장비 구출 및 운영'
      ],
      tages: [
        'Security'
      ]
    },
  ];