import React from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';

const gutterSizeToClassNameMap = {
  none: null,
  xs: 'hlFlexBox--gutterExtraSmall',
  s: 'hlFlexBox--gutterSmall',
  m: 'hlFlexBox--gutterMedium',
  l: 'hlFlexBox--gutterLarge',
  xl: 'hlFlexBox--gutterExtraLarge'
};
const alignItemsToClassNameMap = {
  stretch: null,
  flexStart: 'hlFlexBox--alignItemsFlexStart',
  flexEnd: 'hlFlexBox--alignItemsFlexEnd',
  center: 'hlFlexBox--alignItemsCenter',
  baseline: 'hlFlexBox--alignItemsBaseline'
};
const justifyContentToClassNameMap = {
  flexStart: null,
  flexEnd: 'hlFlexBox--justifyContentFlexEnd',
  center: 'hlFlexBox--justifyContentCenter',
  spaceBetween: 'hlFlexBox--justifyContentSpaceBetween',
  spaceAround: 'hlFlexBox--justifyCotentSpaceAround',
  spaceEvenly: 'hlFlexBox--justifyContentSpaceEvenly'
};
const directionToClassNameMap = {
  row: 'hlFlexBox--directionRow',
  rowReverse: 'hlFlexBox--directionRowReverse',
  column: 'hlFlexBox--directionColumn',
  columnReverse: 'hlFlexBox--directionColumnReverse'
};

export const HlFlexBox = (props) => {
  const {
    children,
    className,
    gutterSize,
    alignItems,
    justifyContent,
    direction
  } = props;

  const classes = classnames('hlFlexBox', gutterSizeToClassNameMap[gutterSize], alignItemsToClassNameMap[alignItems], justifyContentToClassNameMap[justifyContent], directionToClassNameMap[direction], className);

  return (
    <div className={classes}>
      {children}
    </div>
  );
};

HlFlexBox.propTypes = {
  className: propTypes.string,
  gutterSize: propTypes.oneOf(['none', 'xs', 's', 'm', 'l', 'xl']),
  alignItems: propTypes.oneOf(['stretch', 'flexStart', 'flexEnd', 'center', 'baseline']),
  justifyContent: propTypes.oneOf(['flexStart', 'flexEnd', 'center', 'spaceBetween', 'spaceAround', 'spcaeEvenly']),
  direction: propTypes.oneOf(['row', 'rowReverse', 'column', 'columnReverse'])
};