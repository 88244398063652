import React from 'react';
import Navbar from './../Navbar';
import Footer from './../Footer';

export const DesktopLayout = (props) => {
  const {
    children
  } = props;

  return (
    <div className='hlLayout-desktop hlLayout'>
      <Navbar env='desktop' />
      {React.cloneElement(children, { env: 'desktop'})}
      <Footer />
    </div>
  );
};