import React from 'react';
import PropTypes from 'prop-types';


const ViewTitle = ({view}) => {
    let newTitles = '';
    const titles = view.toUpperCase().split("");
    titles.forEach((title, index) => {
        if(index === 0) {
            newTitles += '#';
        }
        newTitles += ' \u00A0\u00A0\u00A0 ' + title;
        if(index === titles.length - 1) {
            newTitles += ' \u00A0\u00A0\u00A0 ' + '#';
        }
    })

    return (
        <div className='header about'>
            <div>
                <div className='header-contents about'>
                    <h5><span>{newTitles}</span></h5>
                    <ul>
                        <li><span></span></li>
                        <li><span></span></li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

ViewTitle.propTypes = {
    view: PropTypes.string.isRequired
}
export default ViewTitle;