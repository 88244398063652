import React from 'react';

const Footer = () => {
    return (
        <div className='footer'>
            <div>
                <div className='row'>
                    <div>
                        <hr></hr>
                        <div className='text-box'>
                            <br />
                            <h3>H &nbsp; A &nbsp; S &nbsp; H &nbsp; # &nbsp; L &nbsp; A &nbsp; B</h3>
                            <br />
                            <span>서울특별시 구로구 디지털로 288 대륭포스트타워 1차 1212  (주) 해시랩</span>
                            <br />
                            <br />
                            <br />
                        </div>
                        <hr></hr>
                    </div>
                    <div className='footer-company-info'>
                        <span>‌COPYRIGHT‌ HASHLAB.NET © 2017. ALL RIGHTS RESERVED.</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;