import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';

export const GROW_SIZES = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
export const HlFlexItem = (props) => {
  const children = props.children,
        className = props.className,
        grow = props.grow === void 0 ? true : props.grow,
        style = props.style;

  const classes = classNames('hlFlexItem', () => {
    return !grow ? 'hlFlexItem--flexGrowZero' : 'hlFlexItem--flexGrow'.concat(grow)
  }, className);

  return (
    <div className={classes} style={style}>
      {children}
    </div>
  );
};

HlFlexItem.propTypes = {
  className: PropTypes.string,
  grow: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, true, false, null]),
  component: PropTypes.any
};
